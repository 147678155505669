<template>
	<client-page class="app--login">
        <v-container>
            <div class="tit-wrap tit-wrap--dot justify-center">
                <h2 class="tit">ID Search</h2>
            </div>

            <div class="mw-500px ma-auto">
                <v-row class="row--small">
                    <v-col cols="12">
                        <v-row no-gutters>
                            <v-col cols="12" md="">
                                <u-text-field v-model="email" placeholder="Email" type="text"></u-text-field>
                            </v-col>
                            <v-col cols="12" md="auto">
                                <v-btn @click="certify" color="secondary" class="v-btn--input w-100">Send verification code</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <u-text-field v-model="otp" placeholder="Certification number input" type="number"></u-text-field>
                    </v-col>
                </v-row>

                <div class="mt-20 mt-md-30">
                    <v-btn block color="secondary" @click="recovery">Search</v-btn>
                </div>
            </div>
        </v-container>


        <!-- <div class="select-chip">
            <select v-model="phone1" class="select" name="">
                <option value="010">010</option>
                <option value="011">011</option>
                <option value="016">016</option>
                <option value="017">017</option>
                <option value="019">019</option>
            </select>
            <input v-model="phone2" class="input" type="number" placeholder="- 없이 입력" @input="phone2 = phone2.replace(/\./g,'')">
        </div> -->
	</client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import UTextField from "@/components/client/dumb/u-text-field.vue";

export default {
	components: {
        UTextField,
		ClientPage,
	},
	data() {
		return {
			email: null,

			otp: null
		};
	},
	methods: {
		validate(recovery = false){
			try{
				if(!this.email) throw new Error("Please enter your e-mail");
				if(recovery && !this.otp) throw new Error("Please enter the verification code");

				return true;
			}
			catch(error){
				alert(error.message);
			}
			return false;
		},

		async certify(){
			if(this.validate()){

				await api.v1.users.certify.post({
					type: "email",
					kind: "username",
					email: this.email
				});

				alert("The verification code has been sent");
			}
		},

		async recovery(){
			if(this.validate(true)){
				await api.v1.users.recovery.post({
					type: "email",
					kind: "username",
					email: this.email,
					otp: this.otp
				});

				alert("Your ID has been sent to your registered email information");
			}
		},
	},
}
</script>
