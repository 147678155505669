var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--login"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--dot justify-center"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("LOGIN")])]), _c('div', {
    staticClass: "mw-400px ma-auto"
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-text-field', {
    attrs: {
      "placeholder": "ID",
      "type": "text"
    },
    model: {
      value: _vm.username,
      callback: function ($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-text-field', {
    attrs: {
      "placeholder": "PASSWORD",
      "type": "password"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.login.apply(null, arguments);
      }
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "value": true,
      "label": "Save ID",
      "hide-details": ""
    },
    model: {
      value: _vm.remember,
      callback: function ($$v) {
        _vm.remember = $$v;
      },
      expression: "remember"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-20 mt-md-30"
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "color": "secondary"
    },
    on: {
      "click": _vm.login
    }
  }, [_vm._v("Login")])], 1), _c('div', {
    staticClass: "mt-10 mt-md-14"
  }, [_c('v-row', {
    staticClass: "row--small font-size-14 grey-90--text",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/join"
    }
  }, [_vm._v("Join")])], 1), _c('v-col', {
    staticClass: "d-inline-flex align-center",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "h-12px",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/login/find-id"
    }
  }, [_vm._v("ID Search")])], 1), _c('v-col', {
    staticClass: "d-inline-flex align-center",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "h-12px",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/login/find-password"
    }
  }, [_vm._v("Password Search")])], 1)], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }