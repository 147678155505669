<template>
	<client-page class="app--login">
        <v-container>
            <div class="tit-wrap tit-wrap--dot justify-center">
                <h2 class="tit">LOGIN</h2>
            </div>

            <div class="mw-400px ma-auto">
                <v-row class="row--small">
                    <v-col cols="12">
                        <u-text-field v-model="username" placeholder="ID" type="text"></u-text-field>
                    </v-col>
                    <v-col cols="12">
                        <u-text-field v-model="password" @keydown.enter="login" placeholder="PASSWORD" type="password"></u-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox v-model="remember" :value="true" label="Save ID" hide-details></v-checkbox>
                    </v-col>
                </v-row>

                <div class="mt-20 mt-md-30">
                    <v-btn block color="secondary" @click="login">Login</v-btn>
                </div>

                <div class="mt-10 mt-md-14">
                    <v-row align="center" justify="center" class="row--small font-size-14 grey-90--text">
                        <v-col cols="auto"><router-link class="link" to="/join">Join</router-link></v-col>
                        <v-col cols="auto" class="d-inline-flex align-center"><v-divider vertical class="h-12px" /></v-col>
                        <v-col cols="auto"><router-link class="link" to="/login/find-id">ID Search</router-link></v-col>
                        <v-col cols="auto" class="d-inline-flex align-center"><v-divider vertical class="h-12px" /></v-col>
                        <v-col cols="auto"><router-link class="link" to="/login/find-password">Password Search</router-link></v-col>
                    </v-row>
                </div>
            </div>
        </v-container>


        <!-- 회원가입/소셜버튼 -->
        <!-- <div class="login-rowgroup login-rowgroup--buttons">
            <div class="login-row">
                <v-btn block x-large color="primary" to="/join/type">회원가입</v-btn>
            </div>
            <div class="login-row">
                <kakao-login client_id="53ce3af6a7f73f20faa30fef193adcfb"></kakao-login>
            </div>
        </div> -->
	</client-page>
</template>

<script>
import CryptoAES from '@/plugins/crypto-aes';
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import UTextField from "@/components/client/dumb/u-text-field.vue";
import KakaoLogin from "@/components/plugins/kakao/kakao-login.vue";

export default{
	components: {
		ClientPage,
        UTextField,
        KakaoLogin,
	},
	data(){
		return {
			username: "",
			password: "",

			remember: false
		}
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
			if(this.$cookies.get('username')) this.username = this.$cookies.get('username');
			// if(this.$cookies.get('password')) this.password = CryptoAES.decrypt(this.$cookies.get('password'));
		},
		async login(){
			try{
				await this.$store.dispatch("login", { username: this.username, password: this.password, remember: this.remember });

				if(this.remember){
					this.$cookies.set('username', this.username);
					this.$cookies.set('password', CryptoAES.encrypt(this.password));
				}

				this.$router.push('/');
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message);
			}
		},
	},
}
</script>
